import React from "react"
import Layout from "../components/layout";
import { Container } from "@material-ui/core";
import { constants } from "../constants";
import { PageHeader } from "../components/PageHeader";
import styled from "@emotion/styled";

const FindWrapper = styled.div`

  text-align: center;
  margin-top: 8rem;
  
  font-size: 32pt;
  @media (max-width: 700px) {
    font-size: 16pt;
  }
  
  p {
  
  }
  
  a {
    display: inline-block;
    margin: 20px 0;
  }
  
`;

const FindPage = () => (
  <>
    <Layout>
      <Container maxWidth="md">

        <PageHeader>
          Find
        </PageHeader>

        <FindWrapper>
          <p>
            Contact me:
          </p>
          <a href={`mailto:${constants.email}`}>
            {constants.email}
          </a>
        </FindWrapper>

      </Container>
    </Layout>
  </>
);

export default FindPage
